<template>
  <van-pull-refresh
    v-model="isLoading"
    pulling-text="Tarik ke bawah untuk refresh..."
    loosing-text="Lepaskan untuk refresh..."
    success-text="Refresh Berhasil"
    @refresh="onRefresh"
  >
    <div class="authentication">
      <BaseHeaderBar
        title="Verifikasi Data"
        :showCustomerService="false"
        backBtnType="2"
        :showBackButton="true"
        color="#FFF"
        bgColor="transparent"
      />
      <div class="content">
        <img
          class="authentication-bg"
          src="@/assets/img/authentication-bg.png"
        />
        <div class="authentication-list">
          <div @click="goIdentity">
            <p class="label">Informasi Identitas</p>
            <p class="status" v-if="authInfo.iddrentityInfoStatus">
              Sudah Verifikasi
            </p>
            <img
              v-else
              class="arrow-right"
              src="@/assets/img/authentication-nav-icon.png"
            />
          </div>
          <div @click="goBasicInfo">
            <p class="label">Infomasi Dasar</p>
            <p class="status" v-if="authInfo.indrformationStatus">
              Sudah Verifikasi
            </p>
            <img
              v-else
              class="arrow-right"
              src="@/assets/img/authentication-nav-icon.png"
            />
          </div>
          <div @click="goBankInfo">
            <p class="label">Informasi Bank</p>
            <p class="status" v-if="authInfo.badrnkStatus">Sudah Verifikasi</p>
            <img
              v-else
              class="arrow-right"
              src="@/assets/img/authentication-nav-icon.png"
            />
          </div>
          <div @click="goFaceRecognition">
            <p class="label">Koleksi wajah</p>
            <p class="status" v-if="authInfo.podrrtraitStatus">
              Sudah Verifikasi
            </p>
            <img
              v-else
              class="arrow-right"
              src="@/assets/img/authentication-nav-icon.png"
            />
          </div>
        </div>
        <van-checkbox v-model="checked">
          <div class="privacy">
            Mengkonfirmasi dan Menyetujui "<a @click.stop="goAgreement"
              >Perjanjian Pinjaman</a
            >"
          </div>
          <template #icon="props">
            <img
              class="check-icon"
              :src="
                require(`@/assets/img/checked-${
                  props.checked ? '' : 'no-'
                }icon.png`)
              "
            />
          </template>
        </van-checkbox>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
      <van-dialog
        v-model="visible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-tip" src="@/assets/img/location-tip.jpg" />
        <div class="location-confirm" @click="onLocationConfirm">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
      <van-dialog
        v-model="denyVisible"
        :showConfirmButton="false"
        close-on-click-overlay
      >
        <img class="location-deny" src="@/assets/img/location-deny.jpg" />
        <div class="location-confirm" @click="onCloseDialog('denyVisible')">
          <img src="@/assets/img/location-confirm.jpg" />
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh, Dialog } from 'vant'
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'
import dialogVisible from '@/mixins/dialogVisible'

import { getMetadata } from '@/utils/getEquipmentInfo.js'

Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  mixins: [webviewBack, dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      denyVisible: false,
      authInfo: {},
      checked: true,
      enhedensdige: !!sessionStorage.getItem('enhedensdige')
    }
  },
  created () {
    this.getAuthInfo()
    if (!this.enhedensdige) {
      this.reportLocation()
      this.p = new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    }
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goFaceRecognition () {
      if (this.authInfo.podrrtraitStatus) {
        Toast('Anda telah menyelesaikan verifikasi pengumpulan wajah')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      if (this.isUseLatestLogic()) {
        this.$router.push({
          path: '/faceRecognition1'
        })
        return
      }
      this.$router.push({
        path: '/faceRecognition'
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      if (!this.authInfo.podrrtraitStatus) {
        Toast('Silakan verifikasi koleksi wajah terlebih dahulu')
        return false
      }
      return true
    },
    onLocationConfirm () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.reportLocation(position.coords).then(() => {
            this.submitOrder()
          })
        }, (err) => {
          console.log(err)
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.denyVisible = true
              this.hasDeny = true
              break
          }
        })
      }
      this.onCloseDialog()
    },
    onSubmit () {
      if (!this.validate()) return
      this.isReportLocation().then(res => {
        if (res.whetherGrab === 1) {
          if (this.enhedensdige) {
            if (this.hasDeny) {
              this.denyVisible = true
            } else {
              this.visible = true
            }
          } else {
            this.startLocation()
            this.p.then(() => {
              this.submitOrder()
            })
          }
        } else {
          this.submitOrder()
        }
      })
    },
    submitOrder () {
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore',
        portraitAdd: 1
      }
      // 获取设备信息
      getMetadata()
        .then(res => {
          // 提交设备信息
          return request.post('deviceInformation', res)
        })
        .then(() => {
          // 提交订单
          return request.post('sudrbmitloanOrder', postData)
        })
        .then(() => {
          // 跳转到首页
          this.$router.go(-1)
        })
        .catch(error => {
          // 错误处理
          console.error(error)
        })
        .finally(() => {
          // 结束操作
          this.flag = false
        })
    },
    onRefresh () {
      this.getAuthInfo(true)
    },
    isReportLocation () {
      return request.post('posisyonnanEstime')
    },
    startLocation () {
      if (!sessionStorage.getItem('enhedensdige')) {
        window.webkit.messageHandlers.getBuKuLocation.postMessage('')
      }
    },
    reportLocation (coordsData = {}) {
      if (this.enhedensdige) {
        const { longitude = 0, latitude = 0, position = '', address = '', country = '', provinces = '', city = '', county = '', street = '' } = coordsData
        const postData = {
          usdrerLongitude: longitude,
          usdrerLatitude: latitude,
          usdrerPositioning: position,
          usdrerAddress: address,
          usdrerCountries: country,
          usdrerProvinces: provinces,
          usdrerCity: city,
          usdrerCounty: county,
          usdrerStreet: street,
          crdrawl_source: 1
        }
        return request.post('grdrabLocation', postData)
      } else {
        // 客户端调用方法
        window.respoToKJSBnseLocation = (data = {}) => {
          console.log(typeof data, data, 'location')
          const { longitBKude = 0, latitBKude = 0, posBKition = '', addrBKess = '', couBKntry = '', proviBKnces = '', ciBKty = '', couBKnty = '', strBKeet = '' } = data
          const postData = {
            usdrerLongitude: longitBKude,
            usdrerLatitude: latitBKude,
            usdrerPositioning: posBKition,
            usdrerAddress: addrBKess,
            usdrerCountries: couBKntry,
            usdrerProvinces: proviBKnces,
            usdrerCity: ciBKty,
            usdrerCounty: couBKnty,
            usdrerStreet: strBKeet,
            crdrawl_source: 1
          }
          request.post('grdrabLocation', postData).then(this.resolve, this.reject)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  margin-top: -88px;
}

.authentication-bg {
  width: 100%;
  height: 382px;
}

.authentication-list {
  position: relative;
  height: 525px;

  padding-top: 80px;
  box-sizing: border-box;
  margin-top: -40px;
  z-index: 1;

  & > div {
    width: 700px;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-bottom: 1px solid #aaaaaa;
    &:nth-child(n + 2) {
      margin-top: 20px;
    }

    .label {
      font-size: 30px;
      color: #4d4d4d;
    }

    .status {
      font-size: 24px;
      color: #fb4f5d;
    }

    .arrow-right {
      width: 55px;
      height: 55px;
    }
  }
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-top: 22px;
  margin-left: 25px;
}

.privacy {
  line-height: 33px;
  margin-top: 34px;
  margin-left: 5px;
  font-size: 24px;
  color: #333;

  a {
    color: #333;
    text-decoration: underline;
  }
}

.confirm-btn {
  .submit-btn;
  margin-top: 87px;
}
.location-tip {
  width: 100%;
  margin: 30px auto 0;
}

.location-confirm {
  width: 578px;
  height: 93px;
  margin: 40px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-deny {
  width: calc(100% - 40px);
  margin: 30px auto 0;
}
</style>
